import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded",staticStyle:{"border":"dashed 2px grey"}},[_c(VSheet,{ref:"dropzoneRef",staticClass:"pa-6 d-flex flex-gap-16 rounded align-center justify-center",attrs:{"color":"grey lighten-2"}},[_c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(!_vm.loading)?_c('div',[_c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(!_vm.isOverDropZone)?_c('div',{staticClass:"d-flex flex-gap-16 align-center"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t(("" + _vm.label))))]),_c(VDivider,{attrs:{"vertical":""}}),_c(VBtn,{attrs:{"color":"primary","depressed":"","small":""},on:{"click":_vm.open}},[_vm._v(" "+_vm._s(_vm.$t('Choose file'))+" ")])],1):_vm._t("hover",function(){return [_c('span',{staticClass:"d-flex flex-column grey--text text--darken-2"},[_c(VIcon,[_vm._v("cloud_upload")]),_c('span',[_vm._v(_vm._s(_vm.$t('Upload image')))])],1)]})],2)],1):_vm._t("loading",function(){return [_c(VProgressCircular,{attrs:{"size":"32","color":"primary","indeterminate":""}})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }